import React from "react"

import styled from "styled-components"

import { matrixBackgroundStyle, device, appColors } from "utils"

import { VSection } from "components/common"

import { AppPricing } from "src/sections/app"

const AppCompareTemplate = ({ slug, app, section, table }) => {
  return (
    <StyledAppCompareTemplate slug={slug}>
      <VSection
        title={section.title}
        subtitle={section.subtitle}
        titleAs="h1"
        subtitleAs="pSmall"
        // backgroundImage={section.backgroundImage}
        // custom={() => <AppCompareTable slug={slug} table={table} />}
        custom={table}
      />
      <AppPricing
        {...app.pricing}
        disabled={app.disabled}
        salesforceAppexchangeUrl={app.salesforceAppexchangeUrl}
      />
    </StyledAppCompareTemplate>
  )
}

const StyledAppCompareTemplate = styled.div`
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);

  --app-theme-color: ${({ slug }) => appColors[slug].dark};
  --app-gradient-color: ${({ slug }) => appColors[slug].textGradient};

  .v_backgroundImage {
    ${matrixBackgroundStyle};
  }
  .v_tgContainer {
    .tgWrap {
      --tg-text-align: center;
      --tg-max-width: 720px;
      margin: 0 auto;
    }
  }

  .v_customContainer {
    --section-mobile-padding-left: 0;
    --section-mobile-padding-right: 0;
    margin-top: var(--sp-56);

    @media ${device.laptop} {
      margin-top: var(--sp-212);
    }
  }
`

export default AppCompareTemplate
